<script>
import settingsService from '@/services/Settings/settingsService';
import financingService from '@/services/Financing/financingService';
import pessoasService from '@/services/Pessoas/pessoasService';
import logsService from '@/services/Logs/logsService';
import attachmentsService from '@/services/Attachments/attachmentsService';

import { DoubleBounce } from 'vue-loading-spinner';

import * as municipios from '@/assets/data/municipios.json';

import FileUpload from 'vue-upload-component';
import ImageCompressor from '@xkeshi/image-compressor';

import {
    EnderecoPadrao,
    CWLStatusCampanha
} from '@/store/cwl_enums';

export default {
  data() {
    return {
      envAppType: process.env.VUE_APP_TYPE,
      financing: {},
      empresa: {},
      pessoaResponsavelPelaEmpresa: {
        email: {},
        telefone: {},
        documento: {},
        dados_complementares: {}
      },
      list: [],
      estados: [],
      cidades: [],
      tipos_controle_pj: [
        {
            codigo: 1,
            descricao: "Privado"
        },
        {
            codigo: 2,
            descricao: "Púbico Federal"
        },
        {
            codigo: 3,
            descricao: "Público estadual ou distrital"
        },
        {
            codigo: 4,
            descricao: "Público municipal"
        }
      ],
      list_sim_nao: ["SIM", "NAO"],
      tipos_instituicao: [
        {
            codigo: "A",
            descricao: "Instituições financeiras"
        },
        {
            codigo: "B",
            descricao: "Investidores Institucionais"
        },
        {
            codigo: "C",
            descricao: "Outras pessoas jurídicas"
        }
      ],
      caracteristicas_negocio: [
        {
            codigo: "I",
            descricao: "Indústria"
        },
        {
            codigo: "C",
            descricao: "Comércio"
        },
        {
            codigo: "S",
            descricao: "Serviço"
        },
        {
            codigo: "A",
            descricao: "Agricultura"
        }
      ],
      lista_estado_civil: [
        {
            codigo: "C",
            descricao: "Casado"
        },
        {
            codigo: "S",
            descricao: "Solteiro"
        },
        {
            codigo: "V",
            descricao: "Viúvo"
        },
        {
            codigo: "Q",
            descricao: "Desquitado"
        },
        {
            codigo: "D",
            descricao: "Divorciado"
        },
        {
            codigo: "N",
            descricao: "Não Informado"
        },
        {
            codigo: "J",
            descricao: "Separado Judicialmente"
        },
        {
            codigo: "U",
            descricao: "União Estável"
        },
        {
            codigo: "O",
            descricao: "Outros"
        },
      ],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      isLoading: true,

      cepForSearch: "",
      showPJAddressFields: true,
      isLoadingPJCidades: false,
      isLoadingEnderecoPessoaPJ: false,
      skipCepSearch: true,

      socios: [],

      // Documentos Anexos
      new_files: [],
      new_file_fields_shown: false,
      new_file_to_add: {},
      tipos_documentos: [],
      perfil_documentos: [],
      accept: 'application/pdf',
      extensions: 'pdf',
      minSize: 1024,
      multiple: false,
      directory: false,
      drop: false,
      dropDirectory: false,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      show_alerta_salvar_documentos: false,
      perfil_documentos_excluidos: [],

      CWLStatusCampanha: CWLStatusCampanha,
      habilitarCampos: false,
    };
  },
  async mounted() {
    await this.get();
    this.habilitarCampos = this.financing.status == CWLStatusCampanha.EmCriacao || this.financing.status == CWLStatusCampanha.RemovidaDoAr;
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    objId() {
      return this.$route.params.id;
    },
    isGestor() {
      return this.currentPessoa.caracteristicas[0] === 'gestor'
    }
  },
  components: {
    DoubleBounce,
    FileUpload
  },
  methods: {
    async get() {
        this.financing = await financingService.findById(this.objId);

        let empresaId = this.financing.dados_complementares.empresa.id;
        this.empresa = await pessoasService.findPessoaById(empresaId);
        this.socios = this.empresa.dados_complementares.socios || [];
        this.cepForSearch = this.empresa.endereco.cep;
        this.showPJAddressFields = true;
        if ( this.empresa.dados_complementares.responsavel.id ) {
            let responsavel = await pessoasService.findPessoaById(this.empresa.dados_complementares.responsavel.id);
            this.pessoaResponsavelPelaEmpresa = responsavel;
        }

        await this.getTipoDocumentos();
        await this.getPerfilDocumentos();

        this.isLoading = false;

        if ( this.socios.length == 0 ) {
            this.addSocio();
        }

        await this.getEstados();
    },

    async getPerfilDocumentos() {
        var cnpj = this.empresa.documento.numero.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

        const result = await attachmentsService.getUserDocuments('DocumentosEmpresariais', cnpj);
        let that = this;

        result.items.map(async (item) => {
            if ( that.empresa.dados_complementares.documentos ) {
                await that.asyncForEach(that.empresa.dados_complementares.documentos, async (empresaPerfilDocumento) => {
                    if ( empresaPerfilDocumento.storage_nome_arquivo == item.name ) {
                    let metadata = await item.getMetadata();

                    const path = 'DocumentosEmpresariais/' + cnpj;
                    let url = await attachmentsService.urlAttachment(item.name, path);
                    let base64 = await attachmentsService.base64Attachment(item.name, path);

                    var binaryData = [];
                    binaryData.push(base64);

                    let thumb = url;
                    let perfilDocumento = {
                        file: {
                            id: empresaPerfilDocumento.id,
                            thumb: thumb,
                            file: new Blob(binaryData, { type: metadata.contentType }),
                            name: item.name,
                            contentType: metadata.contentType
                        },
                        tipo_documento: {
                            nome: empresaPerfilDocumento.tipo_documento.nome
                        },
                        status: "Enviado"
                    };
                    that.perfil_documentos.push(perfilDocumento);
                    }
                });
            }
        });
    },

    addSocio() {
        var socioToAdd = this.$CWLNewPessoaPadrao();
        socioToAdd.key = this.socios.length + 1;
        this.socios.push(socioToAdd);
    },

    deleteSocio() {
        this.socios.splice((this.socios.length - 1), 1);
    },

    async getEstados() {
        this.estados = municipios.estados.map((e) => ({
            name: e.nome,
            uf: e.sigla,
        }));


        const empresaUf = this.empresa.endereco.estado;

        const ufSelected = this.estados.find(
            (e) => e.uf === empresaUf || e.uf == empresaUf.uf,
        );
        this.setPJEstadoSelected(ufSelected);
    },

    setPJEstadoSelected(value) {
        this.cidades = [];
        this.isLoadingPJCidades = true;
        if (!value) {
            this.isLoadingPJCidades = false;
            return;
        }

        const estado = municipios.estados.find((e) => e.sigla === value.uf);
        this.cidades = estado ? estado.cidades : [];
        this.isLoadingPJCidades = false;
    },

    setSocioEstadoSelected(socio) {
        socio.helper_cidades = [];
        socio.helper_is_loading_cidades = true;
        var value = socio.endereco.estado;
        if (!value) {
            socio.helper_is_loading_cidades = false;
            return;
        }
        const estado = municipios.estados.find((e) => e.sigla === value.uf || e.sigla === value);
        socio.helper_cidades = estado ? estado.cidades : [];
        socio.helper_is_loading_cidades = false;
    },

    async save() {
      this.isLoading = true;

      try {
        await this.salvarDocumentos();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.$notify({
          title: 'Erro ao salvar os dados da empresa, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        this.showPJAddressFields = false;

        if (!this.isLoadingEnderecoPessoaPJ) {
          this.isLoadingEnderecoPessoaPJ = true;
          this.empresa.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.empresa.endereco = enderecoFound;
          }

          this.showPJAddressFields = true;
          this.isLoadingEnderecoPessoaPJ = false;
        }
      } else if (!this.isLoadingEnderecoPessoaPJ && !this.skipCepSearch) {
        this.showPJAddressFields = false;
        this.empresa.endereco = {};
      }

      this.skipCepSearch = false;
    },

    async changeSocioCEP(socio) {
        const cepWithoutMask = socio.helper_cep_for_search
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
        if (cepWithoutMask.length == 8) {
            socio.helper_show_address_fields = false;

            if (!socio.helper_is_loading_endereco) {
                socio.helper_is_loading_endereco = true;
                socio.endereco = Object.assign({}, EnderecoPadrao);

                const enderecoFound = await pessoasService.searchAddressByZipcode(
                    socio.helper_cep_for_search,
                );
                if (enderecoFound) {
                    socio.endereco = enderecoFound;
                }

                this.setSocioEstadoSelected(socio);

                socio.helper_show_address_fields = true;
                socio.helper_is_loading_endereco = false;
                let socios_bkp = this.socios;
                this.socios = [];
                this.socios = socios_bkp;
            }
        } else if (!socio.helper_is_loading_endereco) {
            socio.helper_show_address_fields = false;
            socio.endereco = {};
        }
    },

    async getTipoDocumentos() {
      this.tipos_documentos = await attachmentsService.getEmpresaTipoDocumentos();
    },

    removerPerfilDocumento(index) {
      this.perfil_documentos_excluidos.push(this.perfil_documentos[index]);
      this.perfil_documentos.splice(index, 1);
      this.show_alerta_salvar_documentos = true;
    },

    async fileUploadInputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        // Automatic compression
        if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor.compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, { error: err.message || 'compress' })
            })
        }
      }
      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
      if (newFile && newFile.error === '' && newFile.type === "application/pdf" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        newFile.contentType = "application/pdf";
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: "Aguardando Envio"
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;
      }
      // image size
      if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: "Aguardando Envio"
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;

        newFile.error = 'image parsing'
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
        }
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: 'parsing image size'})
        }
        img.src = newFile.blob
      }
    },

    async fileUploadInputFile(newFile, oldFile) {
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    async salvarDocumentos() {
      this.isLoading = true;

      let that = this;
      var cnpj = this.empresa.documento.numero.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

      var errors = 0;
      const run = async () => {
        var empresa = { ...that.empresa };

        // if ( !that.show_alerta_salvar_documentos ) {
        //   var documentFilled = true;
        //   await that.asyncForEach(that.tipos_documentos, async (tipoDocumento) => {
        //     if ( tipoDocumento.obrigatorio && documentFilled ) {
        //       var temEsseDocumento = false;
        //       await that.asyncForEach(empresa.dados_complementares.documentos, async (perfilDocumento) => {
        //         if ( perfilDocumento.tipo_documento.nome == tipoDocumento.nome ) {
        //           temEsseDocumento = true;
        //         }
        //       });
        //       documentFilled = temEsseDocumento;
        //     }
        //   });
        // }
        // else {
          await that.asyncForEach(that.perfil_documentos_excluidos, async (perfilDocumento) => {
            await attachmentsService.deleteAttachment(perfilDocumento.file.name, 'DocumentosEmpresariais', cnpj);
          });

          let pessoa_perfil_documentos = [];
          await that.asyncForEach(that.perfil_documentos, async (perfilDocumento) => {
            let nomeDocumento = perfilDocumento.tipo_documento.nome + "_" + perfilDocumento.file.id;
            try {
              if ( perfilDocumento.status != "Enviado" ) {
                perfilDocumento.status = "Enviando...";
                await attachmentsService.addNewDocumentAttachment(perfilDocumento.file.file, nomeDocumento, 'DocumentosEmpresariais', cnpj);
                perfilDocumento.status = "Enviado";
              }

              let pessoaPerfilDocumento = {
                id: perfilDocumento.file.id,
                tipo_documento: perfilDocumento.tipo_documento,
                storage_nome_arquivo: nomeDocumento
              };
              pessoa_perfil_documentos.push(pessoaPerfilDocumento);
            }
            catch (e) {
              errors++;
              perfilDocumento.status = "Erro ao enviar";
            }
          });

          var documentFilled = true;
          await that.asyncForEach(that.tipos_documentos, async (tipoDocumento) => {
            if ( tipoDocumento.obrigatorio && documentFilled ) {
              var temEsseDocumento = false;
              await that.asyncForEach(pessoa_perfil_documentos, async (perfilDocumento) => {
                if ( perfilDocumento.tipo_documento.nome == tipoDocumento.nome ) {
                  temEsseDocumento = true;
                }
              });
              documentFilled = temEsseDocumento;
            }
          });

          if ( errors > 0 ) {
            that.$notify({
              title: 'Ooopss',
              message: 'Desculpe, ocorreu um erro ao salvar os documentos. Tente novamente.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          }
          else {
            that.show_alerta_salvar_documentos = false;

            await this.asyncForEach(this.socios, async (socio) => {
                socio.helper_cidades = [];
            });

            await this.salvarPessoaResponsavel();
            await this.salvarSocios();

            this.empresa.dados_complementares.responsavel = this.pessoaResponsavelPelaEmpresa;
            this.empresa.dados_complementares.socios = this.socios;
            this.empresa.dados_complementares.documentos = pessoa_perfil_documentos;
            this.financing.dados_complementares.empresa = this.empresa;

            await financingService.update(this.objId, this.financing);
            await pessoasService.updatePessoa(this.empresa.id, this.empresa);

            this.$router.push('/edit-financing/' + this.objId);

            this.isLoading = false;

            this.financing = {};
            this.empresa = {};
            this.pessoaResponsavelPelaEmpresa = {
                email: {},
                telefone: {},
                documento: {},
                dados_complementares: {}
            };

            this.$notify({
                title: 'Os dados da empresa foram salvos com sucesso!',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'success',
                timeout: 3000,
            });
        //   }
        }
      }
      run();
    },

    async salvarPessoaResponsavel() {
        if ( !this.pessoaResponsavelPelaEmpresa.id ) {
            let pessoaCriada = await pessoasService.criarPessoaSemValidacao(this.pessoaResponsavelPelaEmpresa);
            this.pessoaResponsavelPelaEmpresa = pessoaCriada;
        }
        else {
            await pessoasService.updatePessoa(this.pessoaResponsavelPelaEmpresa.id, this.pessoaResponsavelPelaEmpresa);
        }
    },

    async salvarSocios() {
        await this.asyncForEach(this.socios, async (socio) => {
            if ( !socio.id ) {
                let pessoaCriada = await pessoasService.criarPessoaSemValidacao(socio);
                socio = pessoaCriada;
            }
            else {
                await pessoasService.updatePessoa(socio.id, socio);
            }
        });
    }
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="save()">
        <card>
            <h5 slot="header" class="title">
                <router-link :to="'/edit-financing/' + objId" class="fa fa-arrow-left"></router-link>
                Voltar para oferta
            </h5>

            <div v-if="isLoading" class="loading-spinner">
                <double-bounce></double-bounce>
            </div>

            <div class="row" v-if="!isLoading">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Representante da empresa*"
                                v-model="pessoaResponsavelPelaEmpresa.nome"
                                placeholder="Informe o nome do representante"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="CPF do representante*"
                                v-model="pessoaResponsavelPelaEmpresa.documento.numero"
                                placeholder="Informe o CPF"
                                v-mask="['###.###.###-##']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="E-mail do representante*"
                                v-model="pessoaResponsavelPelaEmpresa.email.email"
                                placeholder="Informe o e-mail"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Telefone do representante*"
                                v-model="pessoaResponsavelPelaEmpresa.telefone.telefone"
                                placeholder="Informe o telefone"
                                v-mask="['(##) #####-####']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Data de nascimento do representante*"
                                v-model="pessoaResponsavelPelaEmpresa.dados_complementares.birthday"
                                placeholder="Informe a data de nascimento"
                                v-mask="['##/##/####']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                <div class="row col mt-3" v-if="isGestor && pessoaResponsavelPelaEmpresa.clicksign">
                  <div class="col-md-4" v-if="pessoaResponsavelPelaEmpresa.clicksign.signatario_key && pessoaResponsavelPelaEmpresa.clicksign.is_valid">
                    <base-input
                      label="Integração ClickSign"
                      v-model="pessoaResponsavelPelaEmpresa.clicksign.signatario_key"
                      placeholder="Integração ClickSign"
                      readonly
                    />
                  </div>
                <template v-else>
                    <div class="col-md-2">
                        <base-input
                          label="Códido do Erro"
                          model="pessoaResponsavelPelaEmpresa.clicksign.error_code"
                          placeholder="Códido do Erro"
                          readonly
                        ></base-input>
                    </div>
                <div class="col-md-5">
                    <base-input
                      label="Mensagem de Erro"
                      v-model="pessoaResponsavelPelaEmpresa.clicksign.error_message"
                      placeholder="Mensagem de Erro"
                      readonly
                    ></base-input>
                </div>
                <div class="col-md-4 mt-4">
                    <base-checkbox
                        v-model="pessoaResponsavelPelaEmpresa.dados_complementares.force_add"
                    >
                        Forçar criação de signatário
                    </base-checkbox>
                </div>
            </template>
        </div>
    </div>
</div>
</div>
</card>
        <card v-if="!isLoading">
            <h5 slot="header" class="title">Dados da Empresa</h5>

            <div  v-if="envAppType === 'bacen'"  class="row">
              <div class="col-md-3">
                <base-checkbox
                  v-model="empresa.is_avalista"
                  :disabled="!habilitarCampos"
                >
                  Tornar visível também como avalista
                </base-checkbox>
              </div>
            </div>

            <div class="row">
                <div class="col-md-3 mt-3">
                <base-input
                    label="CEP"
                    v-model="cepForSearch"
                    placeholder="Informe o CEP do endereço"
                    v-mask="['##.###-###']"
                    v-on:input="changeCEP"
                    required
                    :disabled="!habilitarCampos"
                ></base-input>
                <label v-if="isLoadingEnderecoPessoaPJ" style="cursor: default"
                    ><i>Pesquisando...</i></label
                >
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    label="Rua"
                    v-model="empresa.endereco.rua"
                    placeholder="Informe a rua do endereço"
                    required
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                ></base-input>
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    label="Número"
                    v-model="empresa.endereco.numero"
                    placeholder="Informe o número do endereço"
                    required
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                ></base-input>
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    label="Complemento"
                    v-model="empresa.endereco.complemento"
                    placeholder="Informe o complemento do endereço"
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                ></base-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 mt-3">
                <base-input
                    label="Bairro"
                    v-model="empresa.endereco.bairro"
                    placeholder="Informe o bairro do endereço"
                    required
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                ></base-input>
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    required
                    label="Estado*"
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                >
                    <v-select
                    label="uf"
                    class="style-chooser"
                    :options="estados"
                    v-model="empresa.endereco.estado"
                    :components="{ Deselect }"
                    @input="setPJEstadoSelected"
                    :disabled="!habilitarCampos"
                    >
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                        <em style="opacity: 0.9; color: #555"
                            ><b>{{ search }}</b> não encontrada</em
                        >
                        </template>
                    </template>
                    </v-select>
                </base-input>
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    required
                    label="Cidade*"
                    :disabled="!habilitarCampos"
                    v-if="showPJAddressFields"
                >
                    <v-select
                    label="nome"
                    class="style-chooser"
                    :options="cidades"
                    v-model="empresa.endereco.cidade"
                    :components="{ Deselect }"
                    :loading="isLoadingPJCidades"
                    :disabled="!habilitarCampos"
                    >
                    <template #spinner="{ loading }">
                        <div
                        v-if="loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                        ></div>
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                        <em style="opacity: 0.9; color: #555"
                            ><b>{{ search }}</b> não encontrada</em
                        >
                        </template>
                        <em style="opacity: 0.9; color: #555" v-else
                        >Selecione um <b>estado</b></em
                        >
                    </template>
                    </v-select>
                </base-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 mt-3">
                <base-input
                    label="Data de Constituição*"
                    v-model="empresa.dados_complementares.data_constituicao"
                    placeholder="Informe a data de constituição"
                    v-mask="['##/##/####']"
                    required
                    :disabled="!habilitarCampos"
                ></base-input>
                </div>
                <div class="col-md-3 mt-3">
                <base-input
                    label="Inscrição Estadual*"
                    v-model="empresa.dados_complementares.inscricao_estadual"
                    placeholder="Informe a inscrição estadual"
                    required
                    :disabled="!habilitarCampos"
                ></base-input>
                </div>
                <div class="col-md-3 mt-3">
                    <label for="capital_social">
                        Capital Social*
                    </label>
                    <currency-input
                        id="capital_social"
                        class="form-control"
                        required
                        v-model="empresa.dados_complementares.capital_social"
                        placeholder="Informe o capital social"
                        currency="BRL"
                        locale="pt-br"
                        :auto-decimal-mode="true"
                        :disabled="!habilitarCampos"
                    />
                </div>
                <div class="col-md-3 mt-3">
                    <base-input
                        required
                        label="Optante pelo Simples*"
                        :disabled="!habilitarCampos"
                    >
                        <v-select
                        class="style-chooser"
                        :options="list_sim_nao"
                        v-model="empresa.dados_complementares.optante_simples"
                        :components="{ Deselect }"
                        :disabled="!habilitarCampos"
                        >
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                            <em style="opacity: 0.9; color: #555"
                                ><b>{{ search }}</b> não encontrada</em
                            >
                            </template>
                        </template>
                        </v-select>
                    </base-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 mt-3">
                    <base-input
                        required
                        label="Tipo de Controle*"
                        :disabled="!habilitarCampos"
                    >
                        <v-select
                        label="descricao"
                        class="style-chooser"
                        :options="tipos_controle_pj"
                        v-model="empresa.dados_complementares.tipo_controle_pj"
                        :components="{ Deselect }"
                        :disabled="!habilitarCampos"
                        >
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                            <em style="opacity: 0.9; color: #555"
                                ><b>{{ search }}</b> não encontrada</em
                            >
                            </template>
                        </template>
                        </v-select>
                    </base-input>
                </div>
                <div class="col-md-3 mt-3">
                    <base-input
                        required
                        label="Tipo de Instituição*"
                        :disabled="!habilitarCampos"
                    >
                        <v-select
                        label="descricao"
                        class="style-chooser"
                        :options="tipos_instituicao"
                        v-model="empresa.dados_complementares.tipo_instituicao"
                        :components="{ Deselect }"
                        :disabled="!habilitarCampos"
                        >
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                            <em style="opacity: 0.9; color: #555"
                                ><b>{{ search }}</b> não encontrada</em
                            >
                            </template>
                        </template>
                        </v-select>
                    </base-input>
                </div>
                <div class="col-md-3 mt-3">
                    <base-input
                        required
                        label="Característica do Negócio*"
                        :disabled="!habilitarCampos"
                    >
                        <v-select
                        label="descricao"
                        class="style-chooser"
                        :options="caracteristicas_negocio"
                        v-model="empresa.dados_complementares.caracteristica_negocio"
                        :components="{ Deselect }"
                        :disabled="!habilitarCampos"
                        >
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                            <em style="opacity: 0.9; color: #555"
                                ><b>{{ search }}</b> não encontrada</em
                            >
                            </template>
                        </template>
                        </v-select>
                    </base-input>
                </div>
                <div class="col-md-3 mt-3">
                    <label for="faturamento_anual">
                        Faturamento anual*
                    </label>
                    <currency-input
                        id="faturamento_anual"
                        class="form-control"
                        required
                        v-model="empresa.dados_complementares.faturamento_anual"
                        placeholder="Informe o faturamento anual"
                        currency="BRL"
                        locale="pt-br"
                        :auto-decimal-mode="true"
                        :disabled="!habilitarCampos"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 mt-3">
                    <base-input
                        label="Código da atividade*"
                        v-model="empresa.dados_complementares.codigo_atividade"
                        placeholder="Informe o código da atividade"
                        v-mask="['##.##-#-##']"
                        required
                        :disabled="!habilitarCampos"
                    ></base-input>
                </div>
            </div>
        </card>

        <card v-if="!isLoading">
            <h5 slot="header" class="title">Dados dos Sócios</h5>

            <div class="row" v-for="socio in socios" :key="socio.key">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Nome Completo*"
                                v-model="socio.nome"
                                placeholder="Informe o nome completo"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="CPF*"
                                v-model="socio.documento.numero"
                                placeholder="Informe o nome completo"
                                v-mask="['###.###.###-##']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Data de Nascimento*"
                                v-model="socio.dados_complementares.birthday"
                                placeholder="Informe a data de nascimento"
                                v-mask="['##/##/####']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                required
                                label="Estado Civil*"
                                :disabled="!habilitarCampos"
                            >
                                <v-select
                                    label="descricao"
                                    class="style-chooser"
                                    :options="lista_estado_civil"
                                    v-model="socio.dados_complementares.estado_civil"
                                    :components="{ Deselect }"
                                    :disabled="!habilitarCampos"
                                >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                    <em style="opacity: 0.9; color: #555"
                                        ><b>{{ search }}</b> não encontrada</em
                                    >
                                    </template>
                                </template>
                                </v-select>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Número Identidade*"
                                v-model="socio.dados_complementares.rg"
                                placeholder="Informe o número da identidade"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Órgao Expeditor*"
                                v-model="socio.dados_complementares.rg_orgao_expeditor"
                                placeholder="Informe o órgao expeditor"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="UF Órgao Expeditor*"
                                v-model="socio.dados_complementares.rg_uf_orgao_expeditor"
                                placeholder="Informe o UF do órgao expeditor"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Data Emissão RG*"
                                v-model="socio.dados_complementares.rg_data_emissao"
                                placeholder="Informe a data de emissão do RG"
                                v-mask="['##/##/####']"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                required
                                label="Assina pela empresa*"
                                :disabled="!habilitarCampos"
                            >
                                <v-select
                                class="style-chooser"
                                :options="list_sim_nao"
                                v-model="socio.dados_complementares.assina_pela_empresa"
                                :components="{ Deselect }"
                                :disabled="!habilitarCampos"
                                >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                    <em style="opacity: 0.9; color: #555"
                                        ><b>{{ search }}</b> não encontrada</em
                                    >
                                    </template>
                                </template>
                                </v-select>
                            </base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <label for="percentual_empresa">
                                Percentual da Empresa*
                            </label>
                            <base-input
                                id="percentual_empresa"
                                type="number"
                                v-model="socio.dados_complementares.percentual_da_empresa"
                                placeholder="Informe o percentual"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="CEP"
                                v-model="socio.helper_cep_for_search"
                                placeholder="Informe o CEP"
                                v-mask="['##.###-###']"
                                v-on:input="changeSocioCEP(socio)"
                                required
                                :disabled="!habilitarCampos"
                            ></base-input>
                            <label v-if="socio.helper_is_loading_endereco" style="cursor: default"><i>Pesquisando...</i></label>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Rua"
                                v-model="socio.endereco.rua"
                                placeholder="Informe a rua do endereço"
                                required
                                :disabled="!habilitarCampos"
                                v-if="socio.helper_show_address_fields"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Número"
                                v-model="socio.endereco.numero"
                                placeholder="Informe o número do endereço"
                                required
                                :disabled="!habilitarCampos"
                                v-if="socio.helper_show_address_fields"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Complemento"
                                v-model="socio.endereco.complemento"
                                placeholder="Informe o complemento do endereço"
                                :disabled="!habilitarCampos"
                                v-if="socio.helper_show_address_fields"
                            ></base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 mt-3">
                            <base-input
                                label="Bairro"
                                v-model="socio.endereco.bairro"
                                placeholder="Informe o bairro do endereço"
                                required
                                :disabled="!habilitarCampos"
                                v-if="socio.helper_show_address_fields"
                            ></base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                required
                                label="Estado*"
                                v-if="socio.helper_show_address_fields"
                                :disabled="!habilitarCampos"
                            >
                                <v-select
                                label="uf"
                                class="style-chooser"
                                :options="estados"
                                v-model="socio.endereco.estado"
                                :components="{ Deselect }"
                                @input="setSocioEstadoSelected(socio)"
                                :disabled="!habilitarCampos"
                                >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                    <em style="opacity: 0.9; color: #555"
                                        ><b>{{ search }}</b> não encontrada</em
                                    >
                                    </template>
                                </template>
                                </v-select>
                            </base-input>
                        </div>
                        <div class="col-md-3 mt-3">
                            <base-input
                                required
                                label="Cidade*"
                                :disabled="!habilitarCampos"
                                v-if="socio.helper_show_address_fields"
                            >
                                <v-select
                                label="nome"
                                class="style-chooser"
                                :options="socio.helper_cidades"
                                v-model="socio.endereco.cidade"
                                :components="{ Deselect }"
                                :loading="socio.helper_is_loading_cidades"
                                :disabled="!habilitarCampos"
                                >
                                <template #spinner="{ loading }">
                                    <div
                                    v-if="loading"
                                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                                    class="vs__spinner"
                                    ></div>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                    <em style="opacity: 0.9; color: #555"
                                        ><b>{{ search }}</b> não encontrada</em
                                    >
                                    </template>
                                    <em style="opacity: 0.9; color: #555" v-else
                                    >Selecione um <b>estado</b></em
                                    >
                                </template>
                                </v-select>
                            </base-input>
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="habilitarCampos">
                <div class="col-md-12 mt-12">
                    <button type="button" class="btn btn-sm" @click="addSocio">Adicionar Sócio</button>
                    <button v-if="socios.length > 1" type="button" class="btn btn-sm btn-warning" @click="deleteSocio">Remover Sócio</button>
                </div>
            </div>
        </card>

        <card v-if="!isLoading">
            <h5 slot="header" class="title">Documentos da Empresa</h5>

            <div class="row" >
                <div class="col-md-3">
                <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    fill
                    @click="new_file_fields_shown = true;"
                >
                    Adicionar Documentos
                </button>
                </div>
            </div>
            <div class="row" v-if="new_file_fields_shown">
                <div class="col-md-12">
                <base-input label="Tipo de Documento" style="font-weight: bold;">
                    <select
                    class="form-control col-md-3"
                    v-model="new_file_to_add"
                    >
                    <option
                        :value="tipo_documento"
                        v-for="(tipo_documento, index) in tipos_documentos"
                        :key="index"
                    >
                        {{ tipo_documento.nome }}
                    </option>
                    </select>
                </base-input>
                <span><i>{{ new_file_to_add.orientacao }}</i></span>
                </div>
            </div>
            <div class="row" v-if="new_file_fields_shown && new_file_to_add.nome">
                <div class="col-md-3">
                <file-upload
                    class="btn btn-primary"
                    :extensions="extensions"
                    :accept="accept"
                    :multiple="multiple"
                    :directory="directory"
                    :create-directory="createDirectory"
                    :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                    :drop="drop"
                    :drop-directory="dropDirectory"
                    v-model="new_files"
                    @input-filter="fileUploadInputFilter"
                    @input-file="fileUploadInputFile"

                    ref="upload">
                    Selecione o documento
                </file-upload>
                </div>
            </div>
            <div class="row">
                <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Miniatura</th>
                        <th>Tipo de Documento</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!perfil_documentos.length">
                        <td colspan="4">
                        <div class="text-center p-5">
                            <h4>Não existem documentos cadastrados. <br>Por favor, clique em Adicionar Documentos.</h4>
                        </div>
                        </td>
                    </tr>
                    <tr v-for="(perfilDocumento, index) in perfil_documentos" :key="perfilDocumento.file.id">
                        <td @click="downloadDocument(perfilDocumento)">
                        <img class="td-image-thumb" v-if="(perfilDocumento.file.thumb && perfilDocumento.file.contentType != 'application/pdf')" :src="perfilDocumento.file.thumb" />
                        <span v-else-if="perfilDocumento.file.contentType == 'application/pdf'">
                            <img src="@/assets/images/pdf.png" alt="PDF" style="width: 32px;" />
                        </span>
                        <span v-else>--</span>
                        </td>
                        <td>
                            {{perfilDocumento.tipo_documento.nome}}
                        </td>
                        <td style="text-align: center;">
                            {{perfilDocumento.status}}
                        </td>
                        <td style="text-align: center;" v-if="habilitarCampos">
                            <div class="btn-group">
                                <button class="btn btn-warning btn-sm" type="button" @click="removerPerfilDocumento(index)">
                                Remover
                                </button>
                            </div>
                        </td>
                        <td v-if="!habilitarCampos"></td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div v-if="show_alerta_salvar_documentos" class="col-md-12" style="text-align: center; margin-top: -40px; color: red; font-weight: bold;">
                <span><i>*** CLIQUE EM SALVAR PARA CONCLUIR AS ALTERAÇÕES DOS DOCUMENTOS ***</i></span>
                </div>
            </div>
        </card>

        <card v-if="!isLoading && habilitarCampos">
            <button type="submit" class="btn btn-sm">Salvar</button>
        </card>
    </form>
  </div>
</template>
